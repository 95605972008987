<template>
<div class='background text-center'>
  <div class='text-center'>
      <main class="form-signin">
      <form>
          <span id="ibeo-logo"></span>
          <h1 class="h3 mb-3 fw-normal">{{ $t((passwordResetRequest || passwordResetRequestIsSent) ? 'form.password_reset_1' : ((newPassword || newPasswordIsCreated) ? 'form.password_new' : 'form.login')) }}</h1>

          <div v-if="this.errormessage" class="alert alert-danger" role="alert">
            {{this.errormessage}}
          </div>

          <template v-if="passwordResetRequest">
              <div class="form-reset-input mb-3">

                <label for="lnemail2">{{ $t('form.email') }}</label>
                <input type="email" id="lnemail2" v-model="passwordResetEmail" :disabled="processing" />

              </div>

              <div v-if="processing"><i class="fas fa-spinner fa-spin"></i> even geduld...</div>
              <button v-else type="button" class="btn action-button" @click="resetPassword" :disabled="!passwordResetEmail || processing"> {{ $t('form.password_reset_2') }} </button>
              <br />
              <div class="btn-space"></div>
              <a href="#" v-show="!processing" @click="passwordResetRequest = false; errormessage = null" class="text-dark"> {{ $t('form.back') }} </a>

          </template>

          <div v-else-if="passwordResetRequestIsSent" class="mb-2">
            Er is een e-mail naar uw ares verstuurd.<br />
            Open de link in deze e-mail om uw wachtwoord te wijzigen.
          </div>

          <template v-else-if="newPassword">

              <div class="form-signin-input">

                <label for="resetpw-1">{{ $t('form.password_new') }}</label>
                <input type="password" id="resetpw-1" autocomplete="new-password" v-model="newPasswordCredentials.password" :disabled="processing" />
                <label for="resetpw-2">{{ $t('form.password_repeat') }}</label>
                <input type="password" id="resetpw-2" autocomplete="new-password" v-model="newPasswordCredentials.password_confirmation" :disabled="processing" />

              </div>

              <div v-if="processing"><i class="fas fa-spinner fa-spin"></i> even geduld...</div>
              <button v-else type="button" class="btn action-button" @click="loginNewPassword" :disabled="!newPasswordCredentials.password || !newPasswordCredentials.password_confirmation || processing">
                 {{ $t('form.change') }} 
              </button>

          </template>

          <div v-else-if="newPasswordIsCreated" class="mb-2"> 
            Uw wachtwoord is gewijzigd.<br />
            U kunt nu inloggen met uw nieuwe wachtwoord.
            <br /><br />
            <a href="#" v-show="!processing" @click="newPasswordIsCreated = false; errormessage = null" class="text-dark"> {{ $t('form.login') }} </a>
          </div>

          <template v-else>

              <div class="form-signin-input">

                <label for="lnemail">{{ $t('form.email') }}</label>
                <input type="email" id="lnemail" v-model="loginCredentials.email" :disabled="processing" />
                <label for="lnpasswrd">{{ $t('form.password') }}</label>
                <input type="password" id="lnpasswrd" v-model="loginCredentials.password" :disabled="processing" />

              </div>

              <div v-if="processing"><i class="fas fa-spinner fa-spin"></i> even geduld...</div>
              <button v-else type="button" class="btn action-button" @click="login" :disabled="!loginCredentials.email || !loginCredentials.password || processing"> {{ $t('form.login') }} </button>
              <br /><br />
              <a href="#" v-show="!processing" @click="passwordResetRequest = true; errormessage = null" class="text-dark"> {{ $t('form.password_reset_1') }} </a>

          </template>

      </form>
      </main>
  </div>
</div>
    
</template>

<script>
  import loginservice from '@/services/LoginService'
  import store from '@/store/user'
  import http from '@/http-common'

    export default {
        name: 'LoginComponent',
        data() {
            return {
                processing: false,
                errormessage: null,
                loginCredentials: {
                    "email": "",
                    "password": "",
                    "device_name": "pc"
                },
                passwordResetRequest: false,
                passwordResetRequestIsSent: false,
                newPassword: false,
                newPasswordIsCreated: false,
                passwordResetEmail: '',
                newPasswordCredentials: {
                    "email": "",
                    "password": "",
                    "password_confirmation": "",
                    "token": "",
                    "device_name": "pc"
                },
            }
        },
        methods: {
             login() {
                this.processing = true;
                this.errormessage = null;
                loginservice.login(this.loginCredentials).then(response => {
                  this.processing = false;
                  if(response.data && response.data.token != null){
                    store.dispatch('userLogin', response.data)
                    http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
                    window.location.reload();
                  }
                  else{
                    this.errormessage = "Onjuiste e-mail en/of wachtwoord."
                  }
                }).catch(error => {
                  this.processing = false;
                  this.errormessage = "Onjuiste e-mail en/of wachtwoord."
                  console.log('error', error) // create notification-toaster for user error
                })
            },
             handleCallback(code, state) {
                /*this.processing = true;
                loginservice.sendLoginCode(code, state).then(response => {
                  this.processing = false;
                  if (!response.data.approved) {
                    this.errormessage = "Your user account does not have access to NMT Mainport.";
                    console.log('User not approved.', response) // create notification-toaster for user error
                  } else {
                    store.dispatch('userLogin', response.data)
                    http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
                    this.$router.push({
                      name: "Home"
                    })
                  }
                }).catch(error => {
                  this.processing = false;
                  console.log('error', error) // create notification-toaster for user error
                })*/
            },
            resetPassword() {
                this.processing = true;
                this.errormessage = null;
                loginservice.requestNewPassword(this.passwordResetEmail).then(response => {
                  this.processing = false;
                  this.passwordResetRequest = false;
                  this.passwordResetRequestIsSent = true;
                }).catch(error => {
                  this.processing = false;
                  this.errormessage = "Onjuiste e-mail."
                  console.log('error', error) // create notification-toaster for user error
                })
            },
            loginNewPassword(){
              if(this.newPasswordCredentials.password != this.newPasswordCredentials.password_confirmation){
                this.errormessage = "Wachtwoorden komen niet overeen."
                return;
              }
              if(this.newPasswordCredentials.password.length < 6){
                this.errormessage = "Wachtwoord moet ten minste 6 tekens bevatten."
                return;
              }
              this.errormessage = null;
              this.processing = true;
                loginservice.resetPassword(this.newPasswordCredentials).then(response => {
                  this.processing = false;
                  this.newPassword = false;
                  this.newPasswordIsCreated = true;
                  window.history.pushState('password-resetted', '', '/');
                }).catch(error => {
                  this.processing = false;
                  this.errormessage = "Er is iets fout gegaan"
                  console.log('error', error) // create notification-toaster for user error
                })
            }
        },
        mounted () {
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');
          const state = urlParams.get('state');
          const passwordresettoken = window.location.pathname.includes('password/reset') ? window.location.pathname.split('/').at(-1) : false;
          const email = urlParams.get('email');

          // const sessionState = urlParams.get('session_state');
          if (code && state) {
            this.handleCallback(code, state)
          }
          if(passwordresettoken && email){
            this.newPasswordCredentials.email = email;
            this.newPasswordCredentials.token = passwordresettoken;
            this.newPassword = true;
          }
        }
    }
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url(/img/nmt-shipping-homepage-2000x1240.jpg);
  background-size: cover;
}

#ibeo-logo{
  height: 100px;
  width: 210px;
  background-image: url(/ibeo-logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: .5rem
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 50px 20px 20px 20px;
  margin: auto;
  background-color: #f5f5f5;
  border-radius: 25px;
}

.form-signin-input, .form-reset-input{
  text-align: left;
  width: 300px
}

.form-reset-input input {
  margin-bottom: 2rem;
}

.alert{
  text-align: center;
  font-size: 0.9rem
}

.btn-space{
  margin-bottom: 58.6px
}
</style>
