import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/company'

class CompanyService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getCompaniesByCustomer(id){
        return http.get(`/api/company?customer_id=${id}`);
    }
    getCompaniesByCustomerNMBRS(id){
        return http.get(`/api/nmbrs/soap/${id}/company`);
    }
    indexByCompany(filters, params, sort = 'default', sortDirection = 1, page = 1, extrafilters = {}){
        var queryUrl = `customer_id=${filters.customer_id}`
        if (params && page) {
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl += `&page=` + page + `&` + queryParams
        }
        return http.get(`${URL}?${queryUrl}`);
    }
}

export default new CompanyService()