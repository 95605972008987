import http from '@/http-common'
import store from '@/store/user'

const URL = '/api/auth'

class LoginService {
  getLoginUrl() {
    return http.get(`${URL}/get-login-url`)
  }
  sendLoginCode(code, state) {
    return http.post(`${URL}/callback`, {'code': code, 'state': state})
  }
  requestNewPassword(email){
    return http.post(`${URL}/forgot`, {'email': email})
  }
  isLoggedIn() {
    return http.post(`${URL}/is-logged-in`)
  }
  resetPassword(credentials) {
    return http.post(`${URL}/reset`, credentials)
  }
  login(credentials) {
    return http.post(`${URL}/login`, credentials)
  }
  logout() {
    return http.post(`${URL}/logout`)
  }
  openNmbrs(companyId) {
    var queryUrl = ''
    var domain = store.getters.getDomain;
    if(domain){
      queryUrl = `&domain=` + domain.split('.')[0]
    }

    return http.post(`/api/nmbrs/soap/singleSignOn?company_id=${companyId}${queryUrl}`)
  }
}

export default new LoginService()