import {ApiService} from '@/services/ApiService'
import http from '@/http-common'
import store from '@/store/user'

const URL = '/api/contract'

class ContractService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    mail(mail, id){
        return http.post(`${URL}/${id}/mail`, mail);
    }
    mailChanges(mail){
        return http.post(`${URL}/mail-changes`, mail);
    }
    download(id){
        return http.get(`${URL}/${id}/generatePDF`, {
            responseType: 'blob'
        });
    }
    downloadDirect(template, companyId){
        return http.post(`/api/generate-pdf-direct`, {template: template, company_id: companyId}, {
            responseType: 'blob'
        });
    }
    showFromNMBRS(id, company_id, customer_id){
        var queryUrl = ''   
        if(store.getters.getDomain){
            queryUrl = `&domain=` + store.getters.getDomain
        }
        return http.get(`/api/nmbrs/soap/${customer_id}/contract/${id}?company_id=${company_id}${queryUrl}`)
    }
    updateNMBRS(customer_id, data){
        var queryUrl = ''   
        if(store.getters.getDomain){
            queryUrl = `?domain=` + store.getters.getDomain
        }
        return http.put(`/api/nmbrs/soap/${customer_id}/contract/${data['external_id']}${queryUrl}`, data)
    }
}

export default new ContractService()