import {ApiService} from '@/services/ApiService'
import http from '@/http-common'
import store from '@/store/user'

const URL = '/api/customer'

class CustomerService extends ApiService {
  
    constructor(){
        super()
    }
    index(page, params) {
        var queryUrl = '?'
        if (params && page) {
          const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
          queryUrl = `?page=` + page + `&` + queryParams
        }
        else if (params) {
            const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl = `?` + queryParams
        }
        
        if(store.getters.getDomain){
            if (queryUrl != '?') {
                queryUrl = queryUrl + '&'
            }
            queryUrl = queryUrl + `domain=` + store.getters.getDomain
        }

        return http.get(`${this.getUrl()}` + queryUrl)
    }
    getUrl(){
        return URL
    }
    connectToNMBRS(id){
        return http.get(`/api/nmbrs/${id}/get-auth-url`);
    }
}

export default new CustomerService()