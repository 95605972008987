import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/file'

class FileService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    storeWithToken(data, token){
        return http.post(`/api/contract-external-file?token=${token}`, data)
    }
    downloadFile(id, name){
        return http.post(`${URL}/download/${id}`, {
            name: name
        },{
            responseType: 'blob'
        });
    }
}

export default new FileService()