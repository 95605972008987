<template>
  <div class="container p-0" v-if="isTokenNotValid">
    <h5>{{ $t('errors.token_expired_1') }}</h5>
    <p>{{ $t('errors.token_expired_2') }}</p>
  </div>
  <EditNAWSection :visible="!isTokenNotValid && isLoaded" :contractObject="contractData" :nawObject="nawData" :nationalities="nationalities" :countries="countries" 
                  :documentTypes="documentTypes" ref="editnaw" />

  <hr class="text-white">
</template>

<script>
  import EditNAWSection from '@/components/employee/EmployeeNAW.vue'
  import countryService from '@/services/CountryService'
  import nationalityService from '@/services/NationalityService'
  import employeeService from '@/services/EmployeeService'
  import documentTypeService from '@/services/DocumentTypeService'
  import store from '@/store/user'
  import http from '@/http-common'


  export default {
    name: 'EmplyeeFillInNAW',
    components: {
      EditNAWSection
    },
    data() {
      return{
        currentNAWStep: 1,
        maxNAWStep: 4,
        formSection: 1,
        countries: [],
        nationalities: [],
        documentTypes: [],
        token: null,
        isTokenNotValid: false,
        isLoaded: false,
        shouldGenerateContract: true,
        nawData: {
          "employee": {
            "email": "",
            "first_name": "",
            "initials": "",
            "prefix": null,
            "last_name": "",
            "birth_place": "",
            "birth_date": "",
            "nationality": null,
            "gender": "",
            "description": null,
            "bsn_number": "",
            "iban": "",
            "phone_number": "",
            "identification_number": null,
            "identification_expiration_date": null,
            "identification_uploaded": null,
            "birth_country_id": null,
            "company_id": null,
            "identification_file_id": null,
            "identification_nmbrs_document_id": null,
            "employee_status_id": null,
            "address": {
                "postal_code": "",
                "house_number": "",
                "prefix": null,
                "street": "",
                "place": "",
                "latitude": null,
                "longitude": null,
                "country_id": null
            },
          },
          "files": [null, null, null],
          "tax_compensation": 0,
          "tax_compensation_date": null,
          "tax_compensation_signature": null
        },
        contractData: {
          "employee": {},
          "job_function": {
              "tax_compensation": 0,
              "tax_compensation_days": null,
              "tax_compensation_distance": null
          }
        }
      }
    },
    methods: {
      isValid(e){
        let el = e.target ? e.target : e;
        let shouldShowvalidity = true;
        let validityCheck = true;
        if(e.target) this.refreshContract();
        if(el.dataset.field){
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.dataset.field){
          el = el.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.parentElement.parentElement.dataset.field){
          el = el.parentElement.parentElement;
          validityCheck = el.dataset.field;
        }
        else if(el.tagName === "SELECT" && el.value === this.$t('form.select')){
          validityCheck = false;
        }
        else{
          switch(el.type){
            case "email":
              //eslint-disable-next-line
              validityCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(el.value);
              break;
            case "tel":
              validityCheck = /^\+?\d{8,13}$/.test( el.value.replaceAll(" ", "").replaceAll("-", "") );
              break;
            case "number":
              validityCheck = /^[0-9,.]+$/.test(el.value);
              break;
            case "date":
              validityCheck = /^\d{4}-\d{2}-\d{2}$/.test(el.value);
              if(validityCheck && (el.hasAttribute('data-notinpast') || el.hasAttribute('data-notinfuture'))){
                const date = new Date(el.value);
                if(el.hasAttribute('data-notinpast')){
                  validityCheck = date > Date.now();
                }
                else{
                  validityCheck = date < Date.now();
                }
              }
              break;
            case "file":
              break;
            default: {
              switch(el.id){
                case "postal-code":
                  validityCheck = /^\d{4}\s?[a-zA-Z]{2}$/.test(el.value);
                  break;
                default:
                  validityCheck = el.value;
                  break;
              }
            }
          }
        }

        if(shouldShowvalidity)
          this.showValidity(el, validityCheck);
        return validityCheck;
      },
      getCountries() {
          countryService.index(this.token).then(response => {
              this.countries = response.data;
              this.countries.sort(function(x,y){ return x.name =='Nederland'  ? -1 : y.name =='Nederland' ? 1 : 0; });
          }).catch(error => {
              console.log('error', error)
          })
      },
      getNationalities() {
          nationalityService.index(this.token).then(response => {
              this.nationalities = response.data;
              this.nationalities.sort(function(x,y){ return x.name =='Nederlandse'  ? -1 : y.name =='Nederlandse' ? 1 : 0; });
          }).catch(error => {
              console.log('error', error)
          })
      },
      getDocumentTypes(){
        documentTypeService.index(this.token).then(response => {
          this.documentTypes = response.data;
        }).catch(error => {
          console.log('error', error)
        })
      },

      showValidity(el, isValid, showCheckmark = false){
        if(isValid){
          if(showCheckmark)
            el.classList.add('is-valid');
          el.classList.remove('is-invalid');
        }
        else{
          if(showCheckmark)
            el.classList.remove('is-valid');
          el.classList.add('is-invalid');
        }
      },
      refreshContract(){
        this.shouldGenerateContract = true;
      },

      setFiles(fileData){
        let files = fileData;
        const size = files.length - 1;
        for(let i = size; i >= 0; i--){
          if(files[i] == null) files.splice(i, 1);
        }
        return files;
      }
    },
    mounted() {
      http.defaults['headers']['Authorization'] = store.getters.getAuthHeader

      this.formSection = 2;
      this.currentNAWStep = 1;
      const href = window.location.href;
      this.token = href.includes('?token=') ? href.split('?token=')[1] : null;
      this.$refs.editnaw.isEmployeeFillingInNAW = true;
      
      if(this.token){
        this.$refs.editnaw.token = this.token;
        employeeService.showWithToken(this.token).then(response => {
          this.nawData = response.data.employee;
          this.nawData.files = [null, null, null];
          this.isLoaded = true;
        }).catch(error => {
            this.isTokenNotValid = true;
        });
      }
      else{
        this.isTokenNotValid = true;
      }

      //Get countries and nationalities
      this.getCountries();
      this.getNationalities();
      this.getDocumentTypes();
    }
  }
</script>

<style scoped>
@media (min-width: 768px) {
  #main-form{
    border-right: 1px solid var(--dp-primary-color);
    padding: 1.5rem;
    flex: 0 0 auto;
    width: 58.33333333%;
    min-height: 400px
  }
}
@media (max-width: 768px) {
  #progress-sidebar{
    display: none
  }
  #footer-buttons.step-1, #footer-buttons.step-2{
    margin-top: 1.5rem;
    margin-bottom: 1rem
  }
  #footer-buttons:not(.step-1):not(.step-2){
    position: fixed;
    bottom: 4px;
    width: 100vw
  }
  #main-form .row > div{
    width: 100% !important
  }
}
</style>