import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import vueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import PrimeVue from 'primevue/config'
import "bootstrap/dist/css/bootstrap.min.css"
import './assets/dark-blue.css';
import { Vue } from 'vue-class-component'
import { createI18n } from 'vue-i18n'
import messages from './locales'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { eventFromUnknownInput } from '@sentry/browser/types/eventbuilder'

// Type-define 'nl' as the master schema for translations
type MessageSchema = typeof messages.nl

const i18n = createI18n<[MessageSchema], 'nl'>({
   locale: 'nl',
   messages: messages
})

const app = createApp(App).use(store).use(router).use(vueToast).use(PrimeVue).use(i18n).mixin({
    methods: {
        formatDate (value: string, type: string) {
            if(value === null) return '';
            const definedMoment = moment(value).locale(this.$i18n.locale)
            if (type == 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm:ss')
            } else if (type == 'fromNow') {
                return definedMoment.fromNow()
            } else if (type == 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type == 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type == 'longDate') {
                return definedMoment.format('D MMMM YYYY')
            } else if (type == 'tinyDate') {
                return definedMoment.format('DD-MM')
            } else if (type == 'dateTimeHistory') {
                if(definedMoment.isAfter(moment().subtract(1, 'day').endOf('day')))
                    return this.$i18n.t('time.today') + ' ' + definedMoment.format('HH:mm:ss')
                if(definedMoment.isAfter(moment().subtract(2, 'day').endOf('day')))
                    return this.$i18n.t('time.yesterday') + ' ' + definedMoment.format('HH:mm:ss')
                return definedMoment.format('MMMM D HH:mm:ss')
            } 

            return definedMoment.format('YYYY-MM-DD')
        },
        formatCurrency (value: number) {
            return new Intl.NumberFormat('en-NL', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
        },
        formatModuleName(name: string, t: any, isPlural = true){
            const formattedName = 'navigation.' + name.toLowerCase().replace('_','').replace(' ','_');
            return t.tc(formattedName, isPlural ? 2 : 1);
        },
        getInitials(storeName: string) {
          const name = storeName.replace("(IT Support) ", "");
    
          const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    
          const initialsArray = Array.from(name.matchAll(rgx)) || [];
          let initials = '';
    
          const first = initialsArray.shift();
          const second = initialsArray.shift();
    
          if (first && second) {
            initials = first[1] + second[1];
          } else {
            initials = '?';
          }
    
          return initials;
        },
        initDatePicker(DatePicker: any, id?: string, date?: string){
            if(id === undefined){
                const elems = document.querySelectorAll('input.datepicker-input');
                elems.forEach(elem => {
                    const datepicker = new DatePicker(elem, {
                        format: 'dd-mm-yyyy',
                        weekStart: "1",
                        autohide: true
                    });
                });
            }
            else{
                const elem = document.getElementById(id);
                const datepicker = new DatePicker(elem, {
                    format: {
                        format: 'dd-mm-yyyy'
                    },
                    weekStart: "1",
                    autohide: true
                });
                datepicker.setDate(date);
            }
        },     
        preventNonNumbers(e: KeyboardEvent, allowCommas = true, allowNegative = false){
            if(['Backspace', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Tab'].includes(e.key)){
                return;
            }
            if(e.ctrlKey){
                return;
            }
            let regex = /[^0-9]/;
            if(allowCommas && allowNegative) regex = /[^,\-0-9]/;
            else if(allowNegative) regex = /[^\-0-9]/;
            else if(allowCommas) regex = /[^,0-9]/;
            if(regex.test(e.key)){
                e.preventDefault();
            }
            if(allowCommas && e.key === '.'){
                (e.target as (HTMLInputElement)).value += ',';
            }
        },
        toastError(error: any) {
            let message = '';
            console.log("toasterror", error);

            if(error.response && error.response.data && error.response.data.message) {
                message = error.response.data.message;

                // if(Object.keys(error.response.data.errors).length) {
                    message += "<br>"
                    let errorkey = null;
                    for(errorkey in error.response.data.errors) {
                        message += " - " + error.response.data.errors[errorkey] + "<br>";
                    }
                    
                // }
            } else {
                message = 'Unknown error occurred';
            }

            this.$toast.error(message)
        }
    }
});

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DNS,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost:8080", "https://test.hr.ibeo.netvibestest.net/", "https://hr.ibeo.netvibestest.net/", "https://hr.ibeo.nl/", /^\//],
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
});

// Sentry.init({
//     app,
//     dsn: "https://382406751e6ed0e3564f92e2fdfc010e@o1072871.ingest.us.sentry.io/4507984582148096",
//     integrations: [
//       Sentry.browserTracingIntegration({ router }),
//       Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });

app.mount('#app');
