import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta:{
      title: 'Dashboard'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/manuals',
    name: 'manuals',
    meta:{
      title: 'Manuals'
    },
    component: () => import('../views/Manuals.vue')
  },
  {
    path: '/employees',
    name: 'employees',
    meta:{
      title: 'Employees'
    },
    component: () => import('../views/Employees.vue')
  },
  {
    path: '/new-employee',
    name: 'new_employee',
    meta:{
      title: 'New Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/employee-details/:id',
    name: 'show_employee_main',
    meta:{
      title: 'Employee'
    },
    component: () => import('../views/ShowEmployee.vue')
  },
  {
    path: '/edit-employee-main/:id',
    name: 'edit_employee_main',
    meta:{
      title: 'Edit Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/edit-employee-personalia/:id',
    name: 'edit_employee_personalia',
    meta:{
      title: 'Edit Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/edit-employee-naw/:id?',
    name: 'edit_employee_naw',
    meta:{
      title: 'NAW-gegevens invullen'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/edit-employee-profession/:id',
    name: 'edit_employee_profession',
    meta:{
      title: 'Edit Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/edit-employee-salary/:id',
    name: 'edit_employee_salary',
    meta:{
      title: 'Edit Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/edit-employee-contract/:id',
    name: 'edit_employee_contract',
    meta:{
      title: 'Edit Employee'
    },
    component: () => import('../views/NewEmployee.vue')
  },
  {
    path: '/templates',
    name: 'templates',
    meta:{
      title: 'Templates'
    },
    component: () => import('../views/Templates.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta:{
      title: 'Users'
    },
    component: () => import('../views/Users.vue')
  },
  {
    path: '/companies',
    name: 'companies',
    meta:{
      title: 'Companies'
    },
    component: () => import('../views/Companies.vue')
  },
  {
    path: '/charges',
    name: 'charges',
    meta:{
      title: 'Doorbelasting'
    },
    component: () => import('../views/Charges.vue')
  },
  {
    path: '/info_texts',
    name: 'info_texts',
    meta:{
      title: 'Info Texts'
    },
    component: () => import('../views/InfoTexts.vue')
  },
  {
    path: '/customers',
    name: 'customers',
    meta:{
      title: 'Customers'
    },
    component: () => import('../views/Customers.vue')
  },
  {
    path: '/password/reset/:token',
    name: 'password_reset',
    meta:{
      title: 'Password Reset'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/password/reset/',
    name: 'password_reset',
    meta:{
      title: 'Password Reset'
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    meta:{
      title: 'Logs'
    },
    component: () => import('../views/Logs.vue')
  },
  {
    path: '/logs/:id',
    name: 'Details Log',
    meta:{
      title: 'Details Log'
    },
    component: () => import('../components/log/LogDetail.vue')
  },
  // {
  //   path: '/debtors',
  //   name: 'debtors',
  //   meta:{
  //     title: 'Debtors'
  //   },
  //   component: () => import('../views/Debtors.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router
