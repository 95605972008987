import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import http from '@/http-common'

export default createStore({
  state: {
    token: null,
    token_expiration: null,
    user: {
      name: null,
      id: null,
      email: null,
      is_hr: false,
      customers: []
    },
    current_domain: null,
    current_company: null,
    current_company_name: null,
    current_customer: null,
    current_customer_name: null,
    default_schedule: '0,0,0,0,0,0,0,0,0,0,0,0,0,0',
    functions: [],
    departments: []
  },
  plugins: [createPersistedState({
    "key": "user"
  })],
  mutations: {
    LOGIN_SUCCESS(state, userData) {
      console.log('data set', userData);
      state.token = userData.token
      state.token_expiration = userData.token_expiration
      state.user.id = userData.id
      state.user.name = userData.name
      state.user.is_hr = userData.is_hr
      state.user.customers = userData.customers
    },
    LOGOUT_SUCCESS(state) {
      state.token = null
      state.token_expiration = null
      state.current_company = null
      state.current_customer = null
      state.current_company_name = null
      state.current_customer_name = null
      state.functions = []
      state.departments = []
      state.user = {
        id: null,
        name: null,
        email: null,
        is_hr: false,
        customers: []
      }
    },
    SET_COMPANY(state, companyId) {
      state.current_company = companyId
    },
    SET_COMPANY_NAME(state, name) {
      state.current_company_name = name
    },
    SET_FUNCTIONS(state, functions) {
      state.functions = functions
    },
    SET_DEPARTMENTS(state, departments) {
      state.departments = departments
    },
    SET_CUSTOMER(state, customerId) {
      state.current_customer = customerId
    },
    SET_CUSTOMER_NAME(state, name) {
      state.current_customer_name = name
    },
    SET_DOMAIN(state, domain) {
      state.current_domain = domain
    },
    SET_DEFAULT_SCHEDULE(state, schedule) {
      state.default_schedule = schedule
    }
  },
  actions: {
    setFunctions({getters, commit}, response) {
      commit('SET_FUNCTIONS', response)
    },
    setDepartments({getters, commit}, response) {
      commit('SET_DEPARTMENTS', response)
    },
    setCompany({getters, commit}, response) {
      commit('SET_COMPANY', response)
    },
    setCustomer({getters, commit}, response) {
      commit('SET_CUSTOMER', response)
    },
    setCompanyName({getters, commit}, response) {
      commit('SET_COMPANY_NAME', response)
    },
    setCustomerName({getters, commit}, response) {
      commit('SET_CUSTOMER_NAME', response)
    },
    setDefaultSchedule({getters, commit}, response) {
      commit('SET_DEFAULT_SCHEDULE', response)
    },
    setDomain({getters, commit}, response) {
      commit('SET_DOMAIN', response)
    },
    userLogin({getters, commit}, response) {
      commit('LOGIN_SUCCESS', response)
      http.defaults.headers.common['Authorization'] = getters.getAuthHeader
    },
    userLogout({commit}, response) {
      commit('LOGOUT_SUCCESS', response)
      sessionStorage.clear();
      http.defaults.headers.common['Authorization'] = ''
    }
  },
  modules: {
  },
  getters: {
    getAuthHeader: state => {
      if (state.token != null) {
        return 'Bearer ' + state.token
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      if (state.user.email != null) {
        return true
      } else {
        return false
      }
    },
    getName: state => {
        return state.user.name
    },
    getCurrentCompanyId: state => {
        return state.current_company
    },
    getDepartments: state => {
        return state.functions
    },
    getFunctions: state => {
        return state.departments
    },
    getCurrentCustomerId: state => {
        return state.current_customer
    },
    getDomain: state => {
        return state.current_domain
    },
    getCurrentCustomerName: state => {
      return state.current_customer_name
    },
    getCurrentCompanyName: state => {
        return state.current_company_name
    },
    getIsHR: state => {
      return state.user.is_hr
    },
    userId: state => {
        return state.user.id
    },
    getUser: state => {
      return state.user
    },
    getCustomers: state => {
      return state.user.customers
    },
    getDefaultSchedule: state => {
      return state.default_schedule
    }
  }
})
