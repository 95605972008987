<template>

  <ul id='menulist' class="list-unstyled flex-column mb-auto">
    <li>
      <router-link to="/new-employee"><i class="fa-light fa-receipt"></i>{{$tc('navigation.new_employee', 2)}}
      </router-link>
    </li>
    <li>
      <router-link to="/employees"><i class="fa-light fa-receipt"></i>{{$tc('navigation.employees', 2)}}</router-link>
    </li>

    <template v-if="getIsHR">
      <li>
        <hr>
      </li>
      <li>
        <router-link to="/customers"><i class="fa-light fa-receipt"></i>{{$tc('navigation.customers', 2)}}</router-link>
      </li>
      <li>
        <router-link to="/companies"><i class="fa-light fa-receipt"></i>{{$tc('navigation.companies', 2)}}</router-link>
      </li>
      <li>
        <router-link to="/templates"><i class="fa-light fa-receipt"></i>{{$tc('navigation.templates', 2)}}</router-link>
      </li>
      <li>
        <router-link to="/info_texts"><i class="fa-light fa-receipt"></i>{{$tc('navigation.info_texts', 2)}}
        </router-link>
      </li>
      <li>
        <router-link to="/charges"><i class="fa-light fa-receipt"></i>{{$tc('navigation.charges', 2)}}</router-link>
      </li>
      <li>
        <router-link to="/users"><i class="fa-light fa-receipt"></i>{{$tc('navigation.users', 2)}}</router-link>
      </li>
      <li>
        <router-link to="/logs"><i class="fal fa-clock-rotate-left"></i><span>{{$tc('navigation.logs', 2)}}</span></router-link>
      </li>
    </template>

    <li>
      <hr>
    </li>

    <li>
      <a href="/" @click.prevent="openNmbrs()"><i class="fa-light fa-receipt"></i><span class="external-link">NMBRS<i
            class="fa-light fa-arrow-up-right-from-square fa-sm"></i></span></a>
    </li>
  </ul>
  <div class="list-unstyled">
    <!-- <a href="#" class="d-flex align-items-center text-decoration-none p-3" id="dropdownUser2">
      <i class="fa-light fa-receipt"></i>{{$tc('navigation.need_help', 2)}}
    </a> -->
    <a class="dropdown-item" href="#" @click.prevent="logout">Uitloggen</a>
  </div>


  <!-- <div class="dropdown">
    <hr />
    <a
      href="#"
      class="
        d-flex
        align-items-center
        link-dark
        text-decoration-none
        dropdown-toggle
        p-3
      "
      id="dropdownUser2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <template v-if="getProfileImage">
        <img
          :src="getProfileImage"
          alt=""
          width="32"
          height="32"
          class="rounded-circle me-2"
        />
      </template>
      <template v-else>
        <div id='profileimage' class='rounded-circle me-2 bg-primary text-white text-center'>{{getInitials(getName)}}</div>
      </template>
      <strong>{{getName}}</strong>
    </a>
    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
      <li v-for="locale in $i18n.availableLocales.filter(row => row != $i18n.locale)" :key="`locale-${locale}`"><a class="dropdown-item" href="#" @click.prevent="changeLang(locale)">{{$t('locale_name', locale)}}</a></li>
      <li><a class="dropdown-item" href="#" @click.prevent="logout">Sign out</a></li>
    </ul>
  </div> -->
</template>

<script>
  import store from '@/store/user'
  import router from '@/router'
  import loginservice from '@/services/LoginService'
  import toggle from 'bootstrap'

  export default {
    name: "navHeader",
    computed: {
      getName() {
        return store.getters.getName
      },
      getProfileImage() {
        return store.getters.getProfileImage
      },
      getIsHR() {
        return store.getters.getIsHR
      }
    },
    methods: {
      logout() {
        loginservice.logout().then(response => {
          store.dispatch('userLogout')
          router.push('/')
          window.location.reload()
        }).catch(error => {
          console.log('error', error)
        })
      },
      openNmbrs() {
        loginservice.openNmbrs(store.getters.getCurrentCompanyId).then(response => {
          window.open(response.data, '_blank');
        }).catch(error => {
          console.log('error', error)
        })
      }
      // changeLang(locale) {
      //   this.$i18n.locale = locale;
      // }
    },
    watch: {
      $route(to, from) {
        // console.log("route change to", to);
        // console.log("route change from", from);
        if (from.name != undefined && from.name != "Callback") {
          // console.log("Normal navigation.gation");
          return;
        }


        let active_items = document.querySelectorAll('#menulist .link-dark[href="' + to.href + '"]');
        // console.log(active_items, "Length: ", active_items.length);
        if (active_items.length > 0) {
          let active_menu = active_items[0].parentNode.parentNode.parentNode;
          let active_menu_btn = active_menu.previousSibling;



          active_menu.classList.add("show");
          active_menu_btn.setAttribute("aria-expanded", "true");

          // console.log(active_menu.classList, active_menu_btn);
          // active_menu_btn.click();
        }
      }
    }
  };

</script>
