import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/nmbrs/document-type'

class DocumentTypeService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    index(token) {
        var params = token ? ('?token=' + token) : '';
        return http.get(`${this.getUrl()}` + params)
    }
}

export default new DocumentTypeService()